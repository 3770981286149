/* eslint-disable no-console */
import React, { useEffect, useState } from "react"
import styled from "styled-components"

import ExitSurvey from "./ExitSurvey/ExitSurvey"
import SubscriptionsList from "../pages/settings/components/_SubscriptionsList"
import authStore from "../stores/auth"
import { get } from "lodash"
import api from "../utils/api"
import { toastError, toastSuccess } from "../utils/myToasts"

const MySubscriptionsSection = styled.section`
  margin-bottom: 42px;

  .payment-methods-div {
    margin-top: 6px;
  }

  .row {
    display: flex;
    align-items: center;
  }

  .stripe {
    margin-left: 6px;
  }

  .button-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 24px;

    @media screen and (max-width: 960px) {
      flex-direction: column;
    }
  }

  .button-container.grow button {
    flex-grow: 1;
  }

  .button-container button {
    background: white;
    border: 1px solid #c9c9ca;
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 16px;
    cursor: pointer;
    transition: all 0.35s;
    line-height: 1.5;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 1px 3px rgba(0, 0, 0, 0.04),
      0px 5px 10px rgba(0, 0, 0, 0.04);

    &:hover {
      transform: scale(1.02);
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04),
        0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04);
    }

    @media screen and (max-width: 960px) {
      width: 100%;
    }
    @media screen and (min-width: 961px) {
      max-width: 31%;
    }

    &:disabled {
      transform: scale(0.95);
      background: #f0f0f0;
      cursor: progress;
    }
  }

  .button-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;
    font-size: 12px;

    .expand {
      flex: 1;
    }

    .rockPoints {
      width: 55px;
      margin-right: 12px;
      margin-left: 12px;
    }

    .coinbase {
      width: 100px;
    }
  }

  .coinbase-square {
    width: 14px;
    margin-top: 4px;
  }

  .credit-input {
    font-size: 14px;
    line-height: 1.2;
    padding: 14px 24px;
    color: #333;
    border: 1px solid rgba(25, 35, 50, 0.1);
    border-radius: 4px;
    flex: 1;
    margin: 0 14px 24px;
    outline: none;

    &:focus {
      box-shadow: 0 1px 4px 0 rgba(88, 98, 113, 0.08);
      border-color: rgba(25, 35, 50, 0.2);
    }

    @media screen and (max-width: 960px) {
      width: 50%;
    }
  }
`
let captchaRef = null
let _widgetID = undefined
export default function PaymentInfoSection({
  manageSubsLink,
  fetchSubsLink,
  fetchSubscriptions,
  allSubscriptions,
}) {
  const isMPManagedUser = get(
    authStore,
    "state.user.mp_managed_subscription",
    false
  )
  const [greToken, setGreToken] = useState(null)
  const renderRecaptchaV2 = () => {
    if (window.grecaptcha && _widgetID === undefined) {
      const wrapper = document.createElement("div")

      _widgetID = window.grecaptcha.render(wrapper, {
        sitekey: process.env.GRECAPTCHA_V2_KEY,
        callback: handleRecaptchaV2Response,
        theme: "light",
      })

      captchaRef.appendChild(wrapper)
    }
  }
  const fetchSubsWithRecaptcha = async () => {
    let _greToken, v2
    if (greToken) {
      v2 = true
      _greToken = greToken
      fetchSubsLink(_greToken, v2)
    } else {
      v2 = false
      try {
        _greToken = await window.grecaptcha.execute(
          process.env.GRECAPTCHA_KEY,
          {
            action: "submit",
          }
        )
        fetchSubsLink(_greToken, v2)
      } catch (e) {
        console.error(e)
        console.error("Fallback to recaptcha v2")
        return renderRecaptchaV2()
      }
    }
  }
  const handleRef = ref => {
    if (!ref) {
      return
    }
    captchaRef = ref
  }

  useEffect(() => {
    fetchSubsWithRecaptcha()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSubsLink])
  useEffect(() => {
    return () => {
      if (window.grecaptcha && _widgetID) window.grecaptcha.reset(_widgetID)
    }
  })
  const handleRecaptchaV2Response = greToken => {
    fetchSubsLink(greToken, true)
    setGreToken(greToken)
  }
  const [exitFlowOn, setExitFlowOn] = useState(false)
  const [currentSubscriptionId, setCurrentSubscriptionId] = useState(null)

  const handleRenewSubscription = async subId => {
    try {
      if (
        !window.confirm(`Are you sure you want to renew this subscription?\n`)
      ) {
        return
      }
      const renewRes = await api.get(
        `/subscriptions/${subId}/restore_mp_subscription`
      )
      if (renewRes.status === 200) {
        toastSuccess(
          get(
            renewRes,
            "data.message",
            "Your subscription is restored successfully."
          )
        )
        fetchSubscriptions()
      }
    } catch (err) {
      toastError(
        get(err, "response.data.errors[0].detail", "Some error occured")
      )
    }
  }

  return (
    <MySubscriptionsSection>
      <div className="recaptcha-container" ref={handleRef} />
      <SubscriptionsList
        allSubscriptions={allSubscriptions}
        fetchSubscriptions={fetchSubscriptions}
        onCancelAction={subId => {
          setCurrentSubscriptionId(subId)
          setExitFlowOn(true)
        }}
        onRenewAction={handleRenewSubscription}
      />
      <ExitSurvey
        currentSubscriptionId={currentSubscriptionId}
        manageSubsLink={manageSubsLink}
        show={exitFlowOn}
        onHide={() => {
          setExitFlowOn(false)
        }}
        isMPManagedUser={isMPManagedUser}
      />
    </MySubscriptionsSection>
  )
}
