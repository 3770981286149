import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import Modal from "../Modal"
import ReactTooltip from "react-tooltip"
import { MyModalBody } from "../ModalHelpers"
import api from "../../utils/api"
import { toastError } from "../../utils/myToasts"
// import Checkbox from "../../pages/settings/components/_Checkbox"

const ExitModalBody = styled(MyModalBody)`
  flex-direction: column-reverse;
  .small {
    font-size: 14px;
    margin: 10px 0;
  }
  hr {
    margin-bottom: 20px;
  }
  textarea {
    border: 1px solid gray;
    width: 100%;
    border-radius: 4px;
    padding: 10px;
    box-sizing: border-box;
    font-family: "Lato", sans-serif;
  }
`
const CheckboxContainer = styled.div`
  margin-bottom: 20px;

  input[type="checkbox"] {
    opacity: 0;
  }

  label {
    position: relative;
    display: inline-block;

    padding-left: 16px;
    cursor: pointer;
    font-size: 14px;
  }

  input:disabled + label {
    cursor: progress;
    color: #a9a9a9;
  }

  label::before,
  label::after {
    transition: all 0.25s ease-in;
    position: absolute;
    content: "";

    /*Needed for the line-height to take effect*/
    display: inline-block;
  }

  /*Outer box of the fake checkbox*/
  label::before {
    margin-top: 5px;
    height: 20px;
    width: 20px;

    border: 1px solid #c9c9ca;
    border-radius: 100%;

    left: -17px;
    top: -8px;
  }

  /*Checkmark of the fake checkbox*/
  label::after {
    height: 6px;
    width: 10px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    border-color: white;

    transform: rotate(-45deg);

    left: -12px;
    top: 2px;
  }

  /*Hide the checkmark by default*/
  input[type="checkbox"] + label::after {
    content: none;
  }

  /*Unhide on the checked state*/
  input[type="checkbox"]:checked + label::after {
    content: "";
  }

  input[type="checkbox"]:checked + label::before {
    content: "";
    background: #33dd81;
    border-color: #33dd81;
    border-radius: 100%;
  }
`

const ModalFooter = styled.div`
  padding: 0 10%;
  display: flex;
  margin-top: 2rem;
  justify-content: flex-end;
  .button {
    width: 35%;
    text-align: center;

    box-sizing: border-box;
    border-radius: 5px;

    font-size: 14px;
    line-height: 16px;

    padding: 12px;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      opacity: 0.8;
      text-decoration: none;
    }
    &:disabled {
      background: #b0b0b0;
      transform: scale(0.95);
    }
  }

  .button--close {
    border: none !important;
    color: rgb(102, 122, 244);
    font-weight: bold;
    background-color: #fff;
  }
  .button--continue {
    color: #fff;
    background-color: rgb(102, 122, 244);
    border: 1px solid transparent;
  }
`

const ModalHeader = styled.h4`
  margin: 0;
`
// const randId = () => `checkbox_${Math.random()}`

const Checkbox = ({ labelText, id, ...rest }) => {
  return (
    <CheckboxContainer>
      <input type="checkbox" id={id} {...rest} />
      <label htmlFor={id}>{labelText}</label>
    </CheckboxContainer>
  )
}
const reasons = [
  "Pricing",
  "Customer service was not helpful",
  "Found another provider",
  "Product availability",
  "Quality of service",
  "No longer needed",
  "Other",
]
const ExitSurvey = ({
  manageSubsLink,
  show,
  onHide,
  currentSubscriptionId,
  isMPManagedUser,
}) => {
  const [activeSelection, setActiveSelection] = useState("")
  const [description, setDescription] = useState("")
  const [loading, setLoading] = useState(false)
  const [descError, setDescError] = useState("")
  const descriptionInputRef = useRef(null)
  const onDescriptionChange = e => {
    setDescription(e.target.value)
    if (e.target.value.length > 0) {
      setDescError("")
      ReactTooltip.hide(descriptionInputRef.current)
    }
  }
  const onSubmit = async () => {
    if (loading) {
      return
    }
    try {
      setLoading(true)
      if (!description || description.length === 0) {
        setDescError(["Required"])
        ReactTooltip.show(descriptionInputRef.current)
        setLoading(false)
        return
      }
      const res = await api.post(`/account/exit_survey`, {
        reason: activeSelection,
        description: description,
        subscription: currentSubscriptionId,
      })
      if (res.status === 200) {
        if (isMPManagedUser) {
          const cancelRes = await api.get(
            `/subscriptions/${currentSubscriptionId}/cancel_at_period_end`
          )
          if (cancelRes.status === 200) {
            window.location.href = manageSubsLink
            setLoading(false)
            onHide()
          } else {
            throw new Error()
          }
        } else {
          window.location.href = manageSubsLink
          setLoading(false)
          onHide()
        }
      } else {
        toastError("Something went wrong")
        setLoading(false)
      }
    } catch (e) {
      toastError("Something went wrong")
      setLoading(false)
    }
  }
  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  return (
    <React.Fragment>
      <Modal show={show} onHide={onHide}>
        <ExitModalBody>
          <ModalHeader>We are sorry to see you go!</ModalHeader>
          <hr />
          <p className="small font-color-black">
            {" "}
            Please provide a reason for cancelling your subscription.
          </p>
          <p className="small font-color-black">
            {" "}
            We want to do it better next time!
          </p>
          <br />
          {reasons.map((r, i) => (
            <Checkbox
              checked={activeSelection.includes(r)}
              onChange={() => {
                const selected =
                  activeSelection.length === 0 ? [] : activeSelection.split(",")
                const selectedOptionIndex = selected.indexOf(r)
                if (selectedOptionIndex > -1) {
                  selected.splice(selectedOptionIndex, 1)
                } else {
                  selected.push(r)
                }
                setActiveSelection(selected.join(","))
              }}
              id={i}
              key={i}
              labelText={r}
            />
          ))}
          {/* {activeSelection === "Other" && ( */}
          <textarea
            placeholder="Your message..."
            onChange={onDescriptionChange}
            ref={descriptionInputRef}
            data-tip={descError}
            data-multiline={true}
            data-effect="solid"
          />
          {/* )} */}
        </ExitModalBody>

        <ModalFooter>
          <button
            className="button button--close cancelButton"
            onClick={onHide}
          >
            Cancel
          </button>
          <button
            disabled={
              loading ||
              !activeSelection ||
              activeSelection.length === 0 ||
              !description ||
              description.length === 0
            }
            className="button button button--continue"
            onClick={onSubmit}
          >
            Continue
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default ExitSurvey
