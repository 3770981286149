import React, { useEffect } from "react"
import { get } from "lodash"
import moment from "moment"
import styled from "styled-components"

import SectionHeader from "./_SectionHeader"
import Table from "./_Table"

import { getSubscriptionFeature as getFeature } from "../../../store"
import renewLogo from "../../../imgs/renew-proxy-white.svg"

const Container = styled.div`
  margin-bottom: 60px;

  .green,
  .gray {
    font-weight: 700;
  }

  th:nth-child(2),
  td:nth-child(2) {
    @media screen and (max-width: 960px) {
      display: none;
    }
  }

  .mobile-only {
    @media screen and (min-width: 961px) {
      display: none;
    }
  }
`

const CancelButton = styled.button`
  background-color: #667af4;
  font-size: 11px;
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  border: 0;
  display: table;

  text-align: center;
  font-weight: 700;
  cursor: pointer;
  text-transform: uppercase;
`
const RenewButton = styled(CancelButton)`
  display: flex;
  align-items: center;
  gap: 8px;
`

const SubRow = props => {
  const proxy = getFeature(props, "Proxy Type")
  return (
    <tr>
      <td>
        {get(proxy, "servicesOffered", "no service found").split(",")[0]} —{" "}
        {get(proxy, "title", "no title found")}
        <br />
        <span className="small">
          Order#{get(props, "orderNumber", "not found")}
        </span>
        <br className="mobile-only" />
        <span className="small mobile-only">
          {moment(get(props, "createdAt")).format("LL")}
        </span>
      </td>
      <td>{moment(get(props, "createdAt")).format("LL")}</td>

      <td align="">
        {get(props, "autoRenewAt", null) ? (
          <CancelButton onClick={props.onCancelAction}>Cancel</CancelButton>
        ) : (
          <RenewButton onClick={props.onRenewAction}>
            <img
              className="renew-proxy-img"
              src={renewLogo}
              alt="Plan renews"
            ></img>
            <span>Renew</span>
          </RenewButton>
        )}
      </td>
    </tr>
  )
}

export default function RenewalsList({
  allSubscriptions,
  fetchSubscriptions,
  onCancelAction,
  onRenewAction,
}) {
  useEffect(() => {
    fetchSubscriptions()
  }, [])

  return (
    <Container>
      <SectionHeader title="Active Subscriptions" />
      {allSubscriptions.length === 0 ? (
        <p>You dont have any subscription.</p>
      ) : (
        <Table>
          <thead>
            <tr>
              <th className="strong left">Product</th>
              <th className="strong left">Date</th>
              <th className="strong left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {allSubscriptions.map((elem, idx) => {
              return (
                <SubRow
                  key={idx}
                  {...elem}
                  onCancelAction={() => {
                    onCancelAction(elem.id)
                  }}
                  onRenewAction={() => {
                    onRenewAction(elem.id)
                  }}
                />
              )
            })}
          </tbody>
        </Table>
      )}
    </Container>
  )
}
