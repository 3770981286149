import React from "react"

import { Subscribe } from "unstated"
import RPContainer from "../../store"
import RenewalsContainer from "../../stores/renewals"
import RockPointsContainer from "../../stores/rockpoints"

import MySubscriptionsSection from "../../components/MySubscriptionsSection"

import Layout from "./components/_Layout"

function Billing() {
  return (
    <Layout
      name="Billing & Credit"
      title="Manage billing information and view orders"
    >
      <Subscribe to={[RPContainer, RenewalsContainer, RockPointsContainer]}>
        {(rpStore, pointsStore) => (
          <React.Fragment>
            <MySubscriptionsSection
              manageSubsLink={rpStore.state.manageSubsLink}
              fetchSubsLink={rpStore.fetchSubsLink}
              allSubscriptions={rpStore.state.allSubscriptions}
              fetchSubscriptions={rpStore.fetchSubscriptions}
              rpStore={pointsStore}
            />
          </React.Fragment>
        )}
      </Subscribe>
    </Layout>
  )
}

export default Billing
